import { getClientVersion } from "../utils/version";

const serverUrl = new URL(window.location.origin);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const signUpToken = urlParams.get('signUpToken') || undefined;

let loginUri = `${serverUrl}login`;
const loginUriQueryParams: string[] = [];
if (signUpToken !== undefined) {
    loginUriQueryParams.push(`signUpToken=${signUpToken}`);
}
const desired = getCookie("desired");
eraseCookie("desired")
if (desired !== undefined) {
    loginUriQueryParams.push(`desired=${desired}`);
}
if (loginUriQueryParams.length > 0) {
    loginUri += "?" + loginUriQueryParams.join(";");
}

const oauthClientId = getCookie("oauthClientId");
if (oauthClientId === undefined) {
    throw new Error("Couldn't find oauthClientId cookie, should have been set by server");
}

const googleOnloadElement = document.createElement('div');
googleOnloadElement.id = "g_id_onload";
for (const [attrKey, attrValue] of [
    ["data-login_uri", loginUri],
    ["data-client_id", oauthClientId],
    ["data-context", "signin"],
    ["data-ux_mode", "redirect"],
    ["data-auto_select", "false"],
    ["data-auto_prompt", "false"]
]) {
    googleOnloadElement.setAttribute(attrKey, attrValue);
}
document.body.appendChild(googleOnloadElement);

const gsiScript = document.createElement('script');
gsiScript.type = 'text/javascript';
gsiScript.src = 'https://accounts.google.com/gsi/client';
document.getElementsByTagName('head')[0].appendChild(gsiScript);

let failReason = getCookie("loginFailReason");
eraseCookie("loginFailReason");

if (failReason !== undefined) {
    const feedbackElement = document.getElementsByClassName("feedback")[0] as HTMLElement;
    feedbackElement.style.visibility = "visible";

    let heading: string;
    if (failReason.startsWith("!")) {
        failReason = failReason.substr(1);
        heading = "Unexpected error";
        feedbackElement.style.borderColor = "#dc143c";
        feedbackElement.style.backgroundColor = "#f4b2bf";
    } else {
        heading = "Sign in failed";
        feedbackElement.style.borderColor = "#ff8c00";
        feedbackElement.style.backgroundColor = "#ffd097";
    }

    feedbackElement.innerHTML = `<p><b>${heading}</b></p><p>${failReason}</p>`;
}

const versionFooterElement = document.getElementsByClassName("versionFooter")[0] as HTMLElement;
versionFooterElement.innerText = getClientVersion();

function getCookie(name: string): string | undefined {
    let cookie = {};
    document.cookie.split(';').forEach(el => {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

function eraseCookie(name: string) {
    document.cookie = name + '=; Max-Age=-99999999;';
}
export function getClientVersion() {
    return latestVersion(global.clientVersions);
}

/**
 * Version strings used here contain a timestamp. Return the latest by that measure.
 */
export function latestVersion(versions: string[]) {
    if (versions.length === 0) {
        throw new Error("Must have at least one version string to find the latest");
    }

    let latestVersion = versions[0];
    let latestTimestamp = extractTimestamp(latestVersion);
    for (let i = 1; i < versions.length; i++) {
        const ts = extractTimestamp(versions[i]);
        if (ts > latestTimestamp) {
            latestVersion = versions[i];
            latestTimestamp = ts;
        }
    }

    return latestVersion;
}

/**
 * Return the timestamp part of the passed version (as a string).
 */
export function extractTimestamp(version: string) {
    const match = /.*@ ([0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2})/.exec(version);
    if (match === null) {
        throw new Error(version + " is not a normal version string containing a timestamp");
    }
    return match[1];
}